import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogCloseDirective } from '@directives/src/dialog-close.directive';
import { IconComponent } from '@ui/icon/icon.component';

@Component({
	standalone: true,
	selector: 'tk-modal-header',
	templateUrl: './modal-header.component.html',
	styleUrls: ['./modal-header.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [DialogCloseDirective, IconComponent],
})
export class ModalHeaderComponent {}
